import "./organization.css";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCountryFullName,
  getIndividualUnderscored,
  getMemeUnderscored,
  getOrganizationLogo,
  splitString,
  getEthnicityPercentageByCountry,
  chartColors,
  ethnicityIds,
  getFormattedDate,
} from "../../helpers";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import star from "../../res/ethnicity_icons/jstar_circle_background.png";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import axios from "../../app/api/axios";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import "react-tabs/style/react-tabs.css";

const ORGANIZATION_URL = "/organization/";
const ORG_REPRESENTATION_URL = "/orgEthnicRepresentation/";
const ORG_ALL_INDIVIDUALS_NAMES = "/getAllIndividualsInOrg/";

const Organization = () => {
  const [org, setOrg] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [barData, setBarData] = useState(null);
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const [allIndividuals, setAllIndividuals] = useState([]);
  const [showBarChart, setShowBarChart] = useState(false);
  let { orgName } = useParams();

  useEffect(() => {
    setShowBarChart(false);
    const getOrg = async () => {
      const { data } = await axios.get(ORGANIZATION_URL + orgName);
      setOrg(data[0]);
    };

    let emptyPieData = [
      ["Ethnicity", "Count"],
      ["Jewish", 0],
      ["White", 0],
      ["Black", 0],
      ["MENA", 0],
      ["Hispanic", 0],
      ["East Asian", 0],
      ["Southeast Asian", 0],
      ["South Asian", 0],
      ["Other", 0],
      ["Mixed", 0],
      ["Unknown", 0],
    ];

    let emptyBarData = [
      [
        "Ethnicity",
        "Over / Under Representation (X times overrepresented)",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ],
      ["Jewish", 0, chartColors.Jewish, null],
      ["White", 0, chartColors.White, null],
      ["Black", 0, chartColors.Black, null],
      ["MENA", 0, chartColors.MENA, null],
      ["Hispanic", 0, chartColors.Hispanic, null],
      ["East Asian", 0, chartColors.EastAsian, null],
      ["Southeast Asian", 0, chartColors.SoutheastAsian, null],
      ["South Asian", 0, chartColors.SouthAsian, null],
    ];

    const getAllIndividuals = async () => {
      setChartDataLoading(true);
      setPieData(emptyPieData);
      setBarData(emptyBarData);
      const { data } = await axios.get(ORG_REPRESENTATION_URL + orgName);
      let temp = emptyPieData;
      for (let i = 1; i < data.length; i++) {
        for (let j = 1; j < temp.length; j++) {
          if (data[i].name === temp[j][0]) {
            temp[j][1] = parseInt(data[i].count);
            break;
          }
        }
      }
      setPieData(temp);
      setChartDataLoading(false);
      if (data[0].name === "USA") {
        setShowBarChart(true);
        temp = emptyBarData;
        let total = 0;

        for (let i = 1; i < data.length; i++) {
          total += parseInt(data[i].count);
        }

        for (let i = 1; i < data.length; i++) {
          for (let j = 1; j < temp.length; j++) {
            if (data[i].name === temp[j][0]) {
              let subgroupPercentage = parseInt(data[i].count) / total;
              let totalPopulationPercentage = getEthnicityPercentageByCountry(
                data[i].name,
                data[0].name
              );
              temp[j][1] = parseFloat(
                (
                  (subgroupPercentage / totalPopulationPercentage) * 100 -
                  1
                ).toFixed(2)
              );
              break;
            }
          }
        }

        setBarData(temp);
      }
    };

    getOrg();
    getAllIndividuals();
  }, [orgName]);

  const getAllIndividualsNames = async () => {
    console.log("orgName", orgName);
    const { data } = await axios.get(ORG_ALL_INDIVIDUALS_NAMES + orgName);
    console.log('data', data);
    setAllIndividuals(data);
  };

  const handleTabSelect = (index) => {
    if (index === 1) {
      getAllIndividualsNames();
    }
  };

  const barChartOptions = {
    title: "Over / Under Representation by Ethnicity",
    width: 1000,
    height: 400,
    bar: { groupWidth: "95%" },
    legend: { position: "none" },
  };

  const options = {
    title: "Ethnic Makeup of " + orgName,
    colors: [
      chartColors.Jewish,
      chartColors.White,
      chartColors.Black,
      chartColors.MENA,
      chartColors.Hispanic,
      chartColors.EastAsian,
      chartColors.SoutheastAsian,
      chartColors.SouthAsian,
      chartColors.Other,
      chartColors.Mixed,
      chartColors.Unknown,
    ],
  };

  return (
    <div className="main-section">
      {org && (
        <Tabs onSelect={handleTabSelect}>
          <TabList>
            <Tab>Org Details</Tab>
            <Tab>
              All Members{" "}
              {allIndividuals.length > 0 ? `(${allIndividuals.length})` : ""}
            </Tab>
          </TabList>

          <TabPanel>
            <article>
              <div className="individuals-page">
                <div className="individuals-details">
                  <h1>{org.name}</h1>
                  <hr />
                  <div className="individual-detail-section">
                    {org.summary && (
                      <p>
                        <b>SUMMARY:</b> {org.summary}
                      </p>
                    )}
                  </div>

                  <h1>Prominent Members</h1>
                  <hr />
                  <div className="individual-detail-section">
                    {splitString(org.prominent_individuals).map(
                      (individualName) => (
                        <Link
                          key={individualName}
                          to={getIndividualUnderscored(individualName)}
                        >
                          <p>{individualName}</p>
                        </Link>
                      )
                    )}
                  </div>
                </div>

                <div className="spacer" />

                <div className="organization-image-container">
                  {org &&
                    getOrganizationLogo(org.name, "organization-page-logo")}
                  <div className="individual-image-details">
                    {org.industry && (
                      <p>
                        <b>INDUSTRY:</b> {org.industrystring}
                      </p>
                    )}
                    {org.country && (
                      <p>
                        <b>COUNTRY:</b> {getCountryFullName(org.country)}
                      </p>
                    )}
                    {org.last_updated && (
                      <p>
                        <b>LAST UPDATED</b> {getFormattedDate(org.last_updated)}
                      </p>
                    )}
                    <br />
                    {org.source && (
                      <p>
                        <a href={org.source} target="_blank" rel="noreferrer">
                          SOURCE
                        </a>
                      </p>
                    )}
                    <a href={getMemeUnderscored(org.name)}>SEE COLLAGE</a>
                  </div>
                </div>
              </div>

              <div>
                {chartDataLoading ? (
                  <div className="spinner">
                    {/* You can use any spinner component here */}
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Chart
                      chartType="PieChart"
                      data={pieData}
                      options={options}
                      width={"100%"}
                      height={"400px"}
                    />
                    {showBarChart && (
                      <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={barData}
                        options={barChartOptions}
                      />
                    )}
                    {!showBarChart && (
                      <Tooltip
                        className="tooltip"
                        placement="right-start"
                        title="Bar charts are only displayed for organizations which operate in countries for which racial makeup data is available. The bar chart shows over/under representation relative to the general population of the country/region, so without this data, the bar chart provides no meaningful information. Currently, only US organizations have this data available, but more will be added as time goes by."
                      >
                        <InfoIcon />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </article>
          </TabPanel>
          <TabPanel>
            {allIndividuals && (
              <div className="individuals-page">
                <div className="individual-detail-section">
                  {allIndividuals.map((individual) => (
                    <Link
                      to={getIndividualUnderscored(individual.name)}
                      key={individual.name}
                    >
                      <div className="individual-wrapper">
                        {individual.ethnicity === ethnicityIds.Jewish && (
                          <img
                            src={star}
                            alt="Icon"
                            className="text-individual-image"
                          />
                        )}
                        <p>{individual.name}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default Organization;
