import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";

const MAX_SOURCES = 6;

const SourcesBuilder = forwardRef(
  ({ placeholderText, beginningValues }, ref) => {
    const [inputFields, setInputFields] = useState([]);

    useImperativeHandle(ref, () => ({
      getValues() {
        return inputFields;
      },
    }));

    useEffect(() => {
      if (beginningValues) {
        setInputFields(beginningValues);
      }
    }, [beginningValues]);

    const handleValueChange = (index, event) => {
      const values = [...inputFields];
      values[index] = event.target.value;
      setInputFields(values);
    };

    const handleAddFields = () => {
      if (inputFields.length >= MAX_SOURCES) {
        return;
      }
      setInputFields([...inputFields, ""]);
    };

    const handleRemoveFields = (index) => {
      const fields = [...inputFields];
      fields.splice(index, 1);
      setInputFields(fields);
    };

    return (
      <div className="personal-relation-builder-container">
        {inputFields.map((inputField, index) => (
          <div
            className="personal-relation-builder-input-container"
            key={index}
          >
            <TextField
              className="personal-relation-builder-input"
              type="text"
              size="small"
              placeholder={placeholderText}
              value={inputField}
              onChange={(event) => handleValueChange(index, event)}
            />

            <button
              className="personal-relation-builder-delete-button"
              type="button"
              onClick={() => handleRemoveFields(index)}
            >
              <ClearIcon />
            </button>
          </div>
        ))}

        <button
          className="personal-relation-builder-add-button"
          type="button"
          onClick={handleAddFields}
        >
          <AddIcon />
          <b>Add {placeholderText}</b>
        </button>
      </div>
    );
  }
);

export default SourcesBuilder;
