import "./PersonalRelationsBuidler.css";

import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useState, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "../../app/api/axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

const INDIVIDUAL_SEARCH_URL = "/searchIndividualOnly/";
const MAX_RELATIONS = 8;

const PersonalRelationsBuidler = forwardRef(({ beginningValues }, ref) => {
  const [inputFields, setInputFields] = useState([]);

  const [open, setOpen] = useState([]);
  const [options, setOptions] = useState([]);
  // const loading = open && options.length === 0 && query.length > 2;
  const [loading, setLoading] = useState([]);

  useImperativeHandle(ref, () => ({
    getValues() {
      return inputFields;
    },
  }));

  useEffect(() => {
    if (beginningValues) {
      let temp = [];
      let tempOpen = [];
      beginningValues.forEach((value) => {
        let split = value.split(" - ");
        temp.push({ name: split[0], relation: split[1] });
        tempOpen.push(false);
      });
      setInputFields(temp);
      setOpen(tempOpen);
    }
  }, [beginningValues]);

  const getIndividuals = (e, index) => {
    setLoading(true);

    if (e !== null) {
      const searchTerm = e.target.value;

      const values = [...inputFields];
      values[index].name = searchTerm;
      setInputFields(values);

      if (
        searchTerm === undefined ||
        searchTerm === "" ||
        searchTerm.length < 3
      ) {
        setLoading(false);
        return;
      }
      axios
        .get(INDIVIDUAL_SEARCH_URL + searchTerm)
        .then((data) => {
          setLoading(false);
          setOptions(data.data);
        })
        .catch((err) => {
          setLoading(false);

          if (err.response && err.response.status === 404) {
            setOptions(null);
          }
        });
    }
  };

  function debounce(callback, wait) {
    let timerId;
    return function (...args) {
      const context = this;
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(() => {
        timerId = null;
        callback.apply(context, args);
      }, wait);
    };
  }

  const debouncedResults = useMemo((event, index) => {
    return debounce(getIndividuals, 300, event, index);
  }, [inputFields]);
  // Had to add inputFields as a dependency to the useMemo hook so it can be set when user is entering
  // custom text in the input field.
  
  const handleNameChange = (event, value, index) => {
    if (value !== null) {
      const values = [...inputFields];
      values[index].name = value.name;
      setInputFields(values);
    }
  };

  const handleRelationChange = (index, event) => {
    const values = [...inputFields];
    values[index].relation = event.target.value;
    setInputFields(values);
  };

  const handleAddFields = () => {
    if (inputFields.length >= MAX_RELATIONS) {
      return;
    }
    setInputFields([...inputFields, { name: "", relation: "" }]);
    setOpen([...open, false]);
  };

  const handleRemoveFields = (index) => {
    const fields = [...inputFields];
    fields.splice(index, 1);
    setInputFields(fields);
  };

  return (
    <div className="personal-relation-builder-container">
      {inputFields.map((inputField, index) => (
        <div className="personal-relation-builder-input-container" key={index}>
          <Autocomplete
            className="personal-relation-builder-input"
            variant="standard"
            size="small"
            open={open[index]}
            onOpen={() => {
              let temp = [...open];
              temp[index] = true;
              setOpen(temp);
            }}
            onClose={() => {
              let temp = [...open];
              temp[index] = false;
              setOpen(temp);
            }}
            isOptionEqualToValue={(option, value) =>
              option.title === value.title
            }
            // This ensures the component doesn't filter out options that don't match the input.
            // This is the default behavior, but as we'll search on native name in the query and return
            // just 'name', the component would filter these out.
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.name || inputField.name}
            options={options}
            loading={loading[index]}
            openOnFocus={false}
            value={inputField.name}
            onInputChange={(event, value) => debouncedResults(event, index)}
            freeSolo // This allows the user to enter a value that is not in the list
            onChange={(event, value) => handleNameChange(event, value, index)}
            renderInput={(params) => (
              <TextField
                {...params}
                onMouseDownCapture={(e) => e.stopPropagation()}
                label="Name"
                value={inputField.name}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />

          <TextField
            className="personal-relation-builder-input"
            type="text"
            size="small"
            label="Relation"
            placeholder="Nature of relation (e.g. spouse, brother, cousin, etc.)"
            value={inputField.relation}
            onChange={(event) => handleRelationChange(index, event)}
          />

          <button
            className="personal-relation-builder-delete-button"
            type="button"
            onClick={() => handleRemoveFields(index)}
          >
            <ClearIcon />
          </button>
        </div>
      ))}

      <button
        className="personal-relation-builder-add-button"
        type="button"
        onClick={handleAddFields}
      >
        <AddIcon />
        <b>Add Relation</b>
      </button>
    </div>
  );
});

export default PersonalRelationsBuidler;
