import { useState, useEffect } from "react";
import axios from "../../app/api/axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Leaderboard = () => {
  const [interval, setInterval] = useState(7);
  const [leaderboard, setLeaderboard] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1); // Default to '7 days' tab

  function createData(
    ranking,
    username,
    contributedBy,
    personalSite,
    totalPoints,
    contributions
  ) {
    return {
      ranking,
      username,
      contributedBy,
      personalSite,
      totalPoints,
      contributions,
    };
  }

  useEffect(() => {
    const getLeaderboard = async () => {
      setLeaderboard([]);
      const { data } = await axios.get(`/getLeaderboard/${interval}`);
      setLeaderboard(data);
    };
    getLeaderboard();
  }, [interval]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const days = [1, 7, 30, 1000000]; // Mapped to: 1 Day, 7 Days, 30 Days, All Time
    setInterval(days[newValue]);
  };

  const rows = leaderboard.map((item, index) =>
    createData(
      index + 1, // ranking based on array index
      item.username,
      item.contributed_by,
      item.personal_site,
      item.total_points,
      item.contributions
    )
  );

  return (
    <Paper>
      {/* Tabs for selecting interval */}
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tooltip title="Shows contributions made between 12:00 AM and 11:59 PM Eastern Time today">
          <Tab label="1 Day" />
        </Tooltip>
        <Tab label="7 Days" />
        <Tab label="30 Days" />
        <Tab label="All Time" />
      </Tabs>

      {/* Leaderboard Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="leaderboard table">
          <TableHead>
            <TableRow>
              <TableCell>Ranking</TableCell>
              <TableCell align="left">Username</TableCell>
              <TableCell align="left">Total Points</TableCell>
              <TableCell align="left">
                Contributions
                <Tooltip title="Contributions made on or before 2025-03-24 were not tracked separately and will therefore only count as 1 contribution. Additional contributions made after this date will be tracked as separate entires and reflect accurately in this contributions column.">
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ ml: 0.5, verticalAlign: "middle", cursor: "pointer" }}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.username}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.ranking}
                </TableCell>
                <TableCell align="left">
                  {row.personalSite ? (
                    <a
                      href={row.personalSite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.username}
                    </a>
                  ) : (
                    row.username
                  )}
                </TableCell>
                <TableCell align="left">{row.totalPoints}</TableCell>
                <TableCell align="left">{row.contributions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Leaderboard;
