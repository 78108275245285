import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken } = action.payload;
      // console.log("Setting access token: ", accessToken);
      state.token = accessToken;
    },
    setUserInfo: (state, action) => {
      const { payload } = action.payload;
      // console.log("Setting User Info: ", payload);
      state.userInfo = payload;
    },
    logOut: (state, action) => {
      state.token = null;
      state.userInfo = null;
    },
  },
});

export const { setCredentials, setUserInfo, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentUserInfo = (state) => state.auth.userInfo;
