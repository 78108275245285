import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import axios from "../../app/api/axios";
import { Link } from "react-router-dom";

import { getOrganizationUnderscored } from "../../helpers";

import {
  FaPodcast,
  // FaTv,
  // FaNewspaper,
  // FaDollarSign,
  FaFilm,
  FaLandmark,
  // FaStopCircle,
  FaGraduationCap,
  FaFootballBall,
  // FaUserSecret,
} from "react-icons/fa";

const ALL_ORGS_URL = "/allOrgs";

const NavSidebar = () => {
  const [orgs, setOrgs] = useState([]);
  const sevenDays = 7 * 24 * 60 * 60 * 1000;

  const oneDayAgo = (date) => {
    const now = new Date();
    const myDate = new Date(date); // date comes in as a string, needs to be date object
    const msDif = now - myDate;

    // return true if the date is within the last 7 days
    return msDif <= sevenDays;
  };

  const getOrgs = async () => {
    const { data } = await axios.get(ALL_ORGS_URL);

    const organizations = data;
    setOrgs(organizations);
  };

  useEffect(() => {
    getOrgs();
  }, []);

  const industryList = [
    { name: "Podcasts", icon: <FaPodcast /> },
    // { name: "Television", icon: <FaTv /> },
    // { name: "News Media", icon: <FaNewspaper /> },
    // { name: "Finance", icon: <FaDollarSign /> },
    { name: "Entertainment", icon: <FaFilm /> },
    { name: "Politics", icon: <FaLandmark /> },
    // { name: "Pornography", icon: <FaStopCircle /> },
    { name: "Academics", icon: <FaGraduationCap /> },
    { name: "Sports", icon: <FaFootballBall /> },
    // { name: "Secret Societies", icon: <FaUserSecret /> },
  ];

  return (
    <Sidebar className="sidebar">
      <Menu>
        {industryList.map((industry, i) => (
          <SubMenu key={i} label={industry.name} icon={industry.icon}>
            {orgs
              .filter((org) => org.industry === industry.name)
              .map((org, i) => (
                <MenuItem
                  className="org-menu-sub-item"
                  key={i}
                  component={<Link to={getOrganizationUnderscored(org.name)} />}
                  suffix={
                    oneDayAgo(org.created_date) ? (
                      <span className="badge red">NEW</span>
                    ) : null
                  }
                >
                  {org.abbrev_name}
                </MenuItem>
              ))}
          </SubMenu>
        ))}
      </Menu>
    </Sidebar>
  );
};

export default NavSidebar;
