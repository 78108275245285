import React, { useState, useEffect } from "react";
import axios from "../../app/api/axios";
import MemeGenerator from "../MemeGenerator/MemeGenerator";
import { useParams } from "react-router-dom";
import { getOrganizationLogo } from "../../helpers";

const SUMMARY_BY_ORG_URL = "/allindividualsbyorg/";
const ORG_REPRESENTATION_URL = "/orgEthnicRepresentation/";

const MemeGeneratorOrgWrapper = () => {
  const [nameList, setNameList] = useState(null);
  const [pieData, setPieData] = useState(null);
  const { orgName } = useParams();

  useEffect(() => {
    const fetchNameList = async () => {
      const { data } = await axios.get(SUMMARY_BY_ORG_URL + orgName);
      setNameList(data);
    };

    fetchNameList();
  }, [orgName]);

  useEffect(() => {
    const fetchPieData = async () => {
      const { data } = await axios.get(ORG_REPRESENTATION_URL + orgName);
      const emptyPieData = [
        ["Ethnicity", "Count"],
        ["Jewish", 0],
        ["White", 0],
        ["Black", 0],
        ["MENA", 0],
        ["Hispanic", 0],
        ["East Asian", 0],
        ["Southeast Asian", 0],
        ["South Asian", 0],
        ["Other", 0],
        ["Mixed", 0],
        ["Unknown", 0],
      ];
      data.forEach((item) => {
        const idx = emptyPieData.findIndex((entry) => entry[0] === item.name);
        if (idx !== -1) emptyPieData[idx][1] = parseInt(item.count);
      });
      setPieData(emptyPieData);
    };

    fetchPieData();
  }, [orgName]);

  const image = getOrganizationLogo(orgName);

  return (
    <MemeGenerator
      individuals={nameList}
      pieData={pieData}
      image={image}
      orgName={orgName}
      isCustom={false}
      updateNameList={setNameList}
    />
  );
};

export default MemeGeneratorOrgWrapper;
