// toastSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toastDisplayed: localStorage.getItem("toastDisplayed") === "true" || false, // Load from localStorage on initial load
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToastDisplayed: (state, action) => {
      state.toastDisplayed = action.payload;
      localStorage.setItem("toastDisplayed", action.payload); // Save to localStorage whenever the value changes
    },
  },
});

export const { setToastDisplayed } = toastSlice.actions;

export const selectToastDisplayed = (state) => state.toast.toastDisplayed;

export default toastSlice.reducer;
