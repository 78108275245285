import axios from "../../app/api/axios";
import React, { useState, useMemo, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { SocialShareModal } from "../";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import avatarPlaceholder from "../../res/avatar-placeholder.png";
import { Chart } from "react-google-charts";
import {
  getIndividualUnderscored,
  getOrganizationLogo,
  chartColors,
  getBaseUrl,
} from "../../helpers";
import { toast } from "react-toastify";
import star from "../../res/ethnicity_icons/jstar_circle_background.png";
import "react-toastify/dist/ReactToastify.css";
import "./memeGenerator.css";

const SEARCH_URL = "/search/";
const GET_INDIVIDUAL_IMAGE_URL = "/individual_images/";

const MAX_NAME_FONT_SIZE = 17;
const MIN_NAME_FONT_SIZE = 11;
const ROW_CONFIG = [8, 10, 12, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15]; // Defines number of images per row

const MemeGenerator = ({ individuals, pieData, orgName, image, isCustom }) => {
  const [open, setOpen] = useState(false);
  const [totalMembers, setTotalMembers] = useState(0);
  const [nameOptions, setNameOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customNameList, setCustomNameList] = useState([]);
  const [customName, setCustomName] = useState("");
  const [memeNameList, setMemeNameList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [jewPercent, setJewPercent] = useState(0);

  const collageRef = useRef();
  let orgNameNoParentheses = orgName
    .replace(/ *\([^)]*\) */g, "")
    .replaceAll("_", " ");
  // Anything in the parentheses at the end of the string
  let match = orgName.match(/\([^)]*\)$/);
  let orgNameParentheses = match ? match[0].replaceAll("_", " ") : "";

  useEffect(() => {
    if (pieData) {
      let total = 0;
      for (let i = 1; i < pieData.length; i++) {
        total += pieData[i][1];
      }
      setJewPercent(((pieData[1][1] / total) * 100).toFixed(1));
      setTotalMembers(total);
    }
  }, [pieData]);

  const getEthnicityImage = (ethnicity) => {
    if (ethnicity === "Jewish") {
      return star;
    }
    return "";
  };

  const getIndividualPortrait = (individual) => {
    if (individual.image_filename) {
      let urlSafeName = individual.image_filename.replace(/ /g, "%20");
      console.log(
        "calling",
        getBaseUrl() + GET_INDIVIDUAL_IMAGE_URL + urlSafeName
      );
      // replace any spaces with %20
      return getBaseUrl() + GET_INDIVIDUAL_IMAGE_URL + urlSafeName;
    } else if (individual.individual_image_url) {
      return individual.individual_image_url;
    } else {
      return avatarPlaceholder;
    }
  };

  const getNameParenthesesRemoved = (name) => {
    return name.replace(/ *\([^)]*\) */g, "");
  };

  const renderRows = () => {
    let imgIndex = 0;
    const rows = [];

    // Render the top image first
    if (orgName) {
      let orgLogo = getOrganizationLogo(orgName, "top-image", true);
      if (orgLogo) {
        rows.push(
          <div key="top-image" className="top-image-wrapper">
            {orgLogo}
          </div>
        );
      } else {
        rows.push(
          <div key="top-image" className="top-image-wrapper">
            <h1>{orgName.replace(/_/g, " ")}</h1>
          </div>
        );
      }
    }

    if (isCustom) {
      individuals = memeNameList;
    }

    // Continue rendering the collage rows
    while (imgIndex < individuals.length) {
      for (const count of ROW_CONFIG) {
        if (imgIndex >= individuals.length) break;
        const rowImages = individuals.slice(imgIndex, imgIndex + count);

        // Calculate font size dynamically for each row
        const rowIndex = rows.length - 1; // Subtract 1 because the first row is for the top image
        const fontSize = `${Math.max(
          MAX_NAME_FONT_SIZE - rowIndex * 2,
          MIN_NAME_FONT_SIZE
        )}px`;

        rows.push(
          <div
            key={imgIndex}
            className="collage-row"
            style={{ "--img-count": count, "--font-size": fontSize }}
          >
            {rowImages.map((individual, idx) => (
              <Link
                to={getIndividualUnderscored(individual.name)}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div key={idx} className="collage-item">
                  <div
                    className="image-wrapper"
                    style={{
                      backgroundImage: `url(${getIndividualPortrait(
                        individual
                      )})`, // Need to use div backgroundImage due to a quirk in html2canvas. images are cropped with object-fit: cover;, but html2canvas doesn't support object-fit, so the images end up stretched
                    }}
                  >
                    {getEthnicityImage(individual.ethnicity) && (
                      <img
                        className="star-overlay"
                        src={getEthnicityImage(individual.ethnicity)}
                        alt=""
                      />
                    )}
                  </div>
                  <p>{getNameParenthesesRemoved(individual.name)}</p>
                </div>
              </Link>
            ))}
          </div>
        );
        imgIndex += count;
      }
    }

    if (jewPercent < 5) {
      rows.push(
        <div key="bottom-section" className="bottom-section">
          <div className="text-container">
            <p>
              <b>{jewPercent}%</b> of <b>{orgNameNoParentheses}</b>{" "}
              {orgNameParentheses} are ethnically Jewish. Despite Jews being
              extremely over-represented in many walks of life, they are not in
              this group. Why might this be? Explore more for yourself at
              SocialSentinel.net
            </p>
          </div>

          {/* Chart Section (30% width) */}
          <div className="chart-container">
            <Chart
              chartType="PieChart"
              width="100%"
              height="100%"
              data={pieData}
              options={options}
            />
          </div>
        </div>
      );
    } else {
      rows.push(
        <div key="bottom-section" className="bottom-section">
          <div className="text-container">
            <p>
              <b>{jewPercent}%</b> of people on <b>{orgNameNoParentheses}</b>{" "}
              {orgNameParentheses} are ethnically Jewish, despite Jews making up
              only <b>2%</b> of the US population. Why might this be the case?
              Explore the data for yourself at SocialSentinel.net
            </p>
          </div>

          {/* Chart Section (30% width) */}
          <div className="chart-container">
            <Chart
              chartType="PieChart"
              width="100%"
              height="100%"
              data={pieData}
              options={options}
            />
          </div>
        </div>
      );
    }

    rows.push(
      <div key="footer-section" className="footer-section">
        <h1>SocialSentinel.net</h1>
      </div>
    );

    if (totalMembers / individuals.length !== 1) {
      rows.push(
        <div key="disclaimer" className="disclaimer">
          <p>
            The individuals shown in this collage do not represent all of the{" "}
            {totalMembers} people associated with this group. Visit
            socialsentinel.net for full robust data
          </p>
        </div>
      );
    }

    return rows;
  };

  const getIndividuals = (e) => {
    setLoading(true);
    const searchTerm = e.target.value;

    if (
      searchTerm === undefined ||
      searchTerm === "" ||
      searchTerm.length < 3
    ) {
      setLoading(false);
      return;
    }
    axios
      .get(SEARCH_URL + searchTerm)
      .then((data) => {
        setLoading(false);
        setNameOptions(data.data);
      })
      .catch((err) => {
        setLoading(false);

        if (err.response && err.response.status === 404) {
          setNameOptions(null);
        }
      });
  };

  function debounce(callback, wait) {
    let timerId;
    return function (...args) {
      const context = this;
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(() => {
        timerId = null;
        callback.apply(context, args);
      }, wait);
    };
  }

  const debouncedResults = useMemo(() => {
    return debounce(getIndividuals, 300);
  }, []);

  const addCustomName = (name) => {
    if (!customNameList.includes(name) && customName !== "") {
      setCustomNameList([...customNameList, name]);
    }
  };

  const handleOnSelect = (item) => {
    setCustomName(item.name);
  };

  const getCustomMemeNames = async () => {
    if (customNameList.length < 7) {
      toast.error("Please add at least 7 names", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else {
      const { data } = await axios.post("/getCustomIndividualsMeme", {
        names: customNameList,
      });
      setMemeNameList(data);
    }
  };

  const removeCustomName = (name) => {
    setCustomNameList((prevList) => {
      const updatedList = prevList.filter((item) => item !== name);
      return updatedList;
    });
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const options = {
    backgroundColor: "transparent",
    chartArea: {
      width: "100%",
      height: "100%",
      backgroundColor: "black", // Ensures chart area is also black
    },
    legend: {
      position: "none",
    },
    fontSize: 16, // Adjust label font size for readability
    pieSliceBorderColor: "black", // Optional: ensures clear separation
    pieSliceText: "label",
    colors: [
      chartColors.Jewish,
      chartColors.White,
      chartColors.Black,
      chartColors.MENA,
      chartColors.Hispanic,
      chartColors.EastAsian,
      chartColors.SoutheastAsian,
      chartColors.SouthAsian,
      chartColors.Other,
      chartColors.Mixed,
      chartColors.Unknown,
    ],
  };

  return (
    <div className="collage-builder-page">
      {isCustom && (
        <div className="custom-collage-builder">
          <h1>Create Your Own Meme</h1>

          {/* Search Section */}
          <div className="search-section">
            <Autocomplete
              className="search-input"
              variant="standard"
              size="small"
              sx={{ "& fieldset": { borderRadius: 33 } }}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              isOptionEqualToValue={(option, value) =>
                option.title === value.title
              }
              // This ensures the component doesn't filter out options that don't match the input.
              // This is the default behavior, but as we'll search on native name in the query and return
              // just 'name', the component would filter these out.
              filterOptions={(nameOptions) => nameOptions}
              getOptionLabel={(nameOptions) => nameOptions.name}
              options={nameOptions}
              loading={loading}
              openOnFocus={false}
              onInputChange={debouncedResults}
              onChange={(_e, value) => {
                if (value) {
                  handleOnSelect(value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter an individual"
                  onMouseDownCapture={(e) => e.stopPropagation()}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
            <button
              className="search-button"
              onClick={() => addCustomName(customName)}
            >
              Add Name
            </button>
          </div>

          {/* Selected List Section */}
          <div className="selected-list">
            <h2>Selected Individuals</h2>
            <ul>
              {customNameList.map((ind, idx) => (
                <li key={idx} className="selected-item">
                  {ind}
                  <Button
                    className="remove-button"
                    onClick={() => removeCustomName(ind)}
                  >
                    ✖
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          {/* Build Button */}
          <button className="build-collage-button" onClick={getCustomMemeNames}>
            GENERATE MEME
          </button>
        </div>
      )}

      <SocialShareModal
        open={modalOpen}
        handleClose={handleModalClose}
        memeName={orgName}
        isCustom={isCustom}
        collageRef={collageRef}
      />

      {/* Render Collage */}
      {individuals && !isCustom && (
        <div className="collage" ref={collageRef}>
          {renderRows()}
        </div>
      )}
      {memeNameList && isCustom && (
        <div className="collage" ref={collageRef}>
          {renderRows()}
        </div>
      )}
      {memeNameList && (
        <div>
          <button
            onClick={handleModalOpen}
            type="button"
            className="share-meme-button"
          >
            Share Or Download Meme
          </button>
        </div>
      )}
    </div>
  );
};

export default MemeGenerator;
