import { OrganizationSummary } from "../";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { carouselTimeout, carouselTransitionTime } from "../../helpers";

const FeaturedIndividuals = () => {
  return (
    <div className="featured-individuals">
      <h1>Featured Organizations</h1>
      <hr />
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={carouselTimeout}
        showThumbs={false}
        transitionTime={carouselTransitionTime}
        showIndicators={false}
      >
        <div className="individuals-summary-container">
          <OrganizationSummary name={"Armchair Expert (Dax Shepard's Podcast)"} />
          <OrganizationSummary name={"The Joe Rogan Experience"} />
          <OrganizationSummary name={"Ben Shapiro's Sunday Special"} />
        </div>
        {/* <div className="individuals-summary-container">
          <OrganizationSummary name={"Male Professional Bodybuilders (According to Wikipedia)"} />
          <OrganizationSummary name={"Ben Shapiro's Sunday Special"} />
          <OrganizationSummary name={"David Pakman Show Guests (Since May 4 2020)"} />
        </div>
        <div className="individuals-summary-container">
          <OrganizationSummary name={"The Charlie Kirk Show"} />
          <OrganizationSummary name={"Forbes Top Billionaire Art Collectors"} />
          <OrganizationSummary name={"Psychoanalytical Theorists (According to Wikipedia)"} />
        </div> */}
      </Carousel>
    </div>
  );
};

export default FeaturedIndividuals;
